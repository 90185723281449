import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

import { Typography, Button } from '@clef/client-library';

import useStyles from './styles';
import ModelDetailsDialogTrainingInProgress from './ModelDetailsDialogTrainingInProgress';
import ModelTrainingProgress from './ModelTrainingProgress';
import { RegisteredModel } from '@clef/shared/types';
import { useStopTrainingMutation } from '@/serverStore/projectModels';
import { useDialog } from '@/components/Layout/components/useDialog';

interface ModelInfoCardTrainingInProgressProps {
  model: RegisteredModel;
}

const ModelInfoCardTrainingInProgress: React.FC<ModelInfoCardTrainingInProgressProps> = ({
  model,
}) => {
  const styles = useStyles();
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const stopTrainingMutation = useStopTrainingMutation();
  const { showConfirmationDialog } = useDialog();

  return (
    <>
      <Box className={styles.modelInfoCard}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mr={-4}>
          <Typography variant="h4_semibold" maxWidth={164}>
            {model.modelName}
          </Typography>
          <Button
            id="view-model-training-detail"
            variant="text"
            size="small"
            color="primary"
            onClick={() => {
              setDialogOpen(true);
            }}
          >
            {t('View Details')}
          </Button>
        </Box>
        <Box className={styles.inProgressChip}>
          <Typography variant="body_small_bold">{t('Training in progress')}</Typography>
        </Box>
        <ModelTrainingProgress modelId={model.id} />
        <Box mt={4}>
          <Button
            id="cancel-model-training"
            variant="outlined"
            color="default"
            disabled={stopTrainingMutation.isLoading}
            onClick={async () => {
              if (stopTrainingMutation.isLoading) return;

              showConfirmationDialog({
                title: t('End Training'),
                content: t(
                  'Are you sure you want to end training? Once ended, training cannot be resumed.',
                ),
                confirmText: t('End Training'),
                onConfirm: async () => {
                  stopTrainingMutation.mutate({ projectId: model.projectId, modelId: model.id });
                },
                color: 'secondary',
              });
            }}
            startIcon={stopTrainingMutation.isLoading && <CircularProgress size={20} />}
          >
            {t('End Training Now')}
          </Button>
        </Box>
      </Box>
      {dialogOpen && (
        <ModelDetailsDialogTrainingInProgress
          model={model}
          open={dialogOpen}
          onClose={() => {
            setDialogOpen(false);
          }}
        />
      )}
    </>
  );
};

export default ModelInfoCardTrainingInProgress;
