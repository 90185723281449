export * from './limits';
export * from './label_utils';
export * from './color';
export * from './basic';
export * from './math';
export * from './array';
export * from './role';
export * from './defect_map';
export * from './user';
export * from './MockedEventSource';
export * from './sleep';
export * from './date';
export * from './model';

export const numberSort = (v1: number, v2: number) => {
  return v1 - v2;
};

// reformat the email, remove the '+' and the string behind, return the original valid email
export const formatEmail = (email: string) => {
  return email.toLocaleLowerCase();
};
