import TiffToPngListWorker from './tiffToPngList.worker.ts';

export type TiffToPngListConverter = (tiffFile: File | Blob) => Promise<File[]>;

/**
 * Convert TIFF to PNG list async using WebWorker
 */
export const tiffToPngListAsync: TiffToPngListConverter = async tiffFile => {
  return new Promise((resolve, reject) => {
    const worker = new TiffToPngListWorker();
    worker.postMessage({ tiffFile });
    const handleMessage = (e: MessageEvent) => {
      const { pngFiles } = e.data as { pngFiles: File[]; messageId: number };
      resolve(pngFiles);
      worker.terminate();
    };
    worker.addEventListener('message', handleMessage);
    worker.addEventListener('error', e => reject(e));
  });
};
