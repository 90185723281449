import React, { useMemo } from 'react';
import { useDropzone, DropzoneOptions, DropzoneRootProps } from 'react-dropzone';
import cx from 'classnames';
import { Grid, Box } from '@material-ui/core';
import { useUploadStyles, useNewUploadStyles } from '../styles';
import { acceptedImageFiles } from '@/utils';

const DEFAULT_ACCEPT = acceptedImageFiles;

export interface DropFileZoneProps {
  onDrop: DropzoneOptions['onDrop'];
  onClick?: DropzoneRootProps['onClick'];
  directory?: boolean;
  accept?: string[];
  multiple?: boolean;
  dataTestId?: string;
  useNewStyle?: boolean;
  hasAcceptedFiles?: boolean;
  disabled?: boolean;
  className?: string;
}

const DropFileZone: React.FC<DropFileZoneProps> = ({
  onDrop,
  onClick,
  children,
  directory,
  accept = [],
  multiple = true,
  dataTestId = 'upload-input',
  useNewStyle = false,
  hasAcceptedFiles = false,
  disabled = false,
  className = '',
}) => {
  const styles = useUploadStyles();
  const newStyles = useNewUploadStyles();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: accept.length ? accept : DEFAULT_ACCEPT,
    noKeyboard: true,
    onDrop: onDrop,
    multiple,
    disabled,
  });

  const currentStyles = useMemo(() => {
    return useNewStyle ? newStyles : styles;
  }, [useNewStyle, newStyles, styles]);

  return (
    <div
      {...getRootProps({
        className: cx(
          currentStyles.dropzoneBaseStyle,
          isDragActive && currentStyles.activeStyle,
          disabled && currentStyles.dropZoneDisabled,
          className,
        ),
        onClick,
      })}
    >
      <input
        {...getInputProps()}
        //@ts-ignore
        directory={directory ? 'true' : undefined}
        webkitdirectory={directory ? 'true' : undefined}
        multiple
        type="file"
        data-testid={dataTestId}
        disabled={disabled}
        id={dataTestId}
      />
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="column"
        className={cx(isDragActive && currentStyles.dropzoneContentFade)}
      >
        {useNewStyle && (
          <Box className={newStyles.dropzoneBox}>
            {hasAcceptedFiles ? t('Added') : t('Required')}
          </Box>
        )}

        {children}
      </Grid>
    </div>
  );
};

export default DropFileZone;
