import React, { createContext, useContext, useEffect } from 'react';
import { MediaWithDatasetContent, Position, ModelStatus } from '@clef/shared/types';
import { Updater, useImmer } from 'use-immer';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useVPLatestModel } from '@/serverStore/projectModels';
import useHandleTrainingComplete from './useHandleTrainingComplete';
import { useModelStatusQuery } from '@/serverStore/projectModels';

export type InstantLearningState = {
  // 'sidebar' and 'fullscreen' will be removed. Keep it for now in case we want to bring the entry point back
  browseImagesMode?: 'sidebar' | 'fullscreen' | 'topbar';
  labelAndTrainMode: 'single';
  mousePos?: Position;
  topbarImages: {
    labeledGroupImages: MediaWithDatasetContent[];
    unlabeledGroupImages: MediaWithDatasetContent[];
  };
  labelingWrapper: {
    showGroundTruthLabels: boolean;
    showPredictionLabeled: boolean;
  };
  openIterating: boolean;
  // trainingState = null means there is no training in progress
  trainingState: null | 'training-in-progress' | 'first-batch-complete';
};

export const initialState: InstantLearningState = {
  labelAndTrainMode: 'single',
  topbarImages: {
    labeledGroupImages: [],
    unlabeledGroupImages: [],
  },
  labelingWrapper: {
    showGroundTruthLabels: false,
    showPredictionLabeled: false,
  },
  openIterating: false,
  trainingState: null,
};

export const InstantLearningContext = createContext<{
  state: InstantLearningState;
  dispatch: Updater<InstantLearningState>;
}>({ state: initialState, dispatch: () => {} });

export const useInstantLearningState = () => useContext(InstantLearningContext);

// right after dispatching a training as the model id is not yet registered on CLEF
export const InstantLearningProjectContextProvider: React.FC<{}> = ({ children }) => {
  const [state, dispatch] = useImmer(initialState);
  const { id: projectId } = useGetSelectedProjectQuery().data ?? {};
  const { latestModel } = useVPLatestModel();
  const { id: latestModelId } = latestModel ?? {};

  const handleInstantLearningTrainingComplete = useHandleTrainingComplete();

  // NOTE: Instant learning jobs are running very fast. Usually, a successful model status fetch after a training is
  // dispatched will directly reach to publishing phase
  const { data: modelStatus } = useModelStatusQuery(projectId, latestModelId);

  // TODO: @tian.lan migrate these with jotai + react query
  // Initializing "isTraining"
  useEffect(() => {
    let trainingState: InstantLearningState['trainingState'] = null;
    if (!modelStatus || modelStatus?.modelId !== latestModelId) {
      return;
    }
    if (!modelStatus?.status) {
      trainingState = null;
    } else if (
      [ModelStatus.Stopped, ModelStatus.Failed, ModelStatus.Terminated].includes(modelStatus.status)
    ) {
      trainingState = null;
    } else if (modelStatus.status === ModelStatus.FirstBatch) {
      trainingState = 'first-batch-complete';
    } else if ([ModelStatus.ALLBatches, ModelStatus.Succeed].includes(modelStatus.status)) {
      // IMPORTANT: ModelStatus.ALLBatches doesn't mean training has finished (there is still publishing happening)
      // However we decide to hide that from user, so to the user, the is the success step
      trainingState = null;
      handleInstantLearningTrainingComplete();
    } else {
      trainingState = 'training-in-progress';
    }

    dispatch(draft => {
      draft.trainingState = trainingState;
    });
  }, [dispatch, modelStatus, handleInstantLearningTrainingComplete, latestModelId]);

  return (
    <InstantLearningContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </InstantLearningContext.Provider>
  );
};
