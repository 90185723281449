import { ModelStatus } from '../types';

export const isModelInEndState = (
  status: ModelStatus | undefined | null,
  metricsReady: boolean | undefined | null,
) => {
  if (!status) {
    return true;
  }

  if (status === ModelStatus.Succeed && metricsReady) {
    return true;
  }

  if (
    status === ModelStatus.Stopped ||
    status === ModelStatus.Failed ||
    status === ModelStatus.Terminated
  ) {
    return true;
  }

  return false;
};
