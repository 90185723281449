import {
  MAX_BRIGHTNESS,
  MIN_BRIGHTNESS,
  MAX_CONTRAST,
  MIN_CONTRAST,
} from '../constants/image_enhancer';

const getImageBlob = function (url: string) {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then(response => {
        const blob = response.blob();
        resolve(blob);
      })
      .catch(e => {
        reject(e);
      });
  });
};

// convert a blob to base64
export const blobToBase64 = (blob: any): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      const dataUrl = reader.result as string;
      resolve(dataUrl);
    };
    reader.onerror = function (e) {
      reject(e);
    };
    reader.readAsDataURL(blob);
  });
};

// retrieve content form a json file
export const getJsonContent = (blob: Blob): Promise<any> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function () {
      const jsonContent = JSON.parse(reader.result as string);
      resolve(jsonContent);
    };
    reader.onerror = function (e) {
      reject(e);
    };
    reader.readAsText(blob);
  });
};

const loadCanvasImage = (source: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.onerror = err => reject(err);

    image.src = source;
  });
};

// combine the previous two functions to return a base64 encode image from url
const getBase64Image = async function (
  url: string | undefined,
  base64Only = false,
): Promise<string | HTMLImageElement> {
  if (!url) {
    return '';
  }
  const blob = await getImageBlob(url);
  const base64 = await blobToBase64(blob);
  if (base64Only) {
    return base64;
  }
  const finalImage = await loadCanvasImage(base64);
  return finalImage;
};

const getPixelBrightness = (pixel: number, brightness: number) => {
  let value = pixel + brightness;
  if (value < 0) {
    value = 0;
  }
  if (value > 255) {
    value = 255;
  }
  return value;
};

const getPixelContrast = (pixel: number, contrastLevel: number) => {
  const factor = (259 * (contrastLevel + 255)) / (255 * (259 - contrastLevel));
  return Math.trunc(factor * (pixel - 128) + 128);
};

function empty(width: number, height: number) {
  const buffer = new ArrayBuffer(width * height * 4);
  const data = new Uint8ClampedArray(buffer);
  const imageData = new ImageData(data, width, height);

  // Set alpha to 255
  for (let i = 3; i < width * height * 4; i += 4) {
    data[i] = 255;
  }

  return imageData;
}

function rgbToLuma(pixel: Uint8ClampedArray, index: number) {
  return pixel[index] * 0.299 + pixel[index + 1] * 0.587 + pixel[index + 2] * 0.114;
}

const applyFilters = (
  imageData: Uint8ClampedArray,
  newData: ImageData,
  width: number,
  height: number,
  brightness: number,
  contrast: number,
  histogramEnabled: boolean,
  contrastEnabled: boolean,
) => {
  const nr = Array(256).fill(0);
  const ng = Array(256).fill(0);
  const nb = Array(256).fill(0);

  const sr = Array(256).fill(0);
  const sg = Array(256).fill(0);
  const sb = Array(256).fill(0);

  if (histogramEnabled) {
    for (let x = 0; x < width; x++) {
      for (let y = 0; y < height; y++) {
        const index = y * (width * 4) + x * 4;
        nr[imageData[index]]++;
        ng[imageData[index + 1]]++;
        nb[imageData[index + 2]]++;
      }
    }
    for (let k = 0; k < 256; k++) {
      for (let j = 0; j <= k; j++) {
        sr[k] = sr[k] + nr[j];
        sg[k] = sg[k] + ng[j];
        sb[k] = sb[k] + nb[j];
      }
      sr[k] = Math.round((sr[k] * 255) / (height * width));
      sg[k] = Math.round((sg[k] * 255) / (height * width));
      sb[k] = Math.round((sb[k] * 255) / (height * width));
    }
  }

  const historgram = new Uint32Array(width);
  for (let i = 0; i < imageData.length; i += 4) {
    var luma = Math.round(rgbToLuma(imageData, i));
    historgram[luma]++;
  }
  const threshold = Math.max(...historgram) * 0.1;
  let min = -1,
    max = -1;

  for (let i = 0; i < width * 0.5; i++) {
    if (historgram[i] > threshold) {
      min = i;
      break;
    }
  }
  if (min < 0) min = 0;

  for (let i = width - 1; i > width * 0.5; i--) {
    if (historgram[i] > threshold) {
      max = i;
      break;
    }
  }
  if (max < 0) max = 255;

  const scale = (255 / (max - min)) * 2;

  for (let x = 0; x < width; x++) {
    for (let y = 0; y < height; y++) {
      const index = y * (width * 4) + x * 4;
      newData.data[index] = imageData[index];
      newData.data[index + 1] = imageData[index + 1];
      newData.data[index + 2] = imageData[index + 2];

      if (brightness != 0) {
        newData.data[index] = getPixelBrightness(newData.data[index], brightness);
        newData.data[index + 1] = getPixelBrightness(newData.data[index + 1], brightness);
        newData.data[index + 2] = getPixelBrightness(newData.data[index + 2], brightness);
      }

      if (contrast != 0) {
        newData.data[index] = getPixelContrast(newData.data[index], contrast);
        newData.data[index + 1] = getPixelContrast(newData.data[index + 1], contrast);
        newData.data[index + 2] = getPixelContrast(newData.data[index + 2], contrast);
      }

      if (histogramEnabled) {
        newData.data[index] = sr[newData.data[index]];
        newData.data[index + 1] = sg[newData.data[index + 1]];
        newData.data[index + 2] = sb[newData.data[index + 2]];
      }

      if (contrastEnabled) {
        newData.data[index] = Math.max(0, newData.data[index] - min) * scale;
        newData.data[index + 1] = Math.max(0, newData.data[index + 1] - min) * scale;
        newData.data[index + 2] = Math.max(0, newData.data[index + 2] - min) * scale;
      }
    }
  }
  return newData?.data;
};

export const setImageFilters = async (
  sourceImage: string | undefined,
  brightnessLevel: number,
  contrastLevel: number,
  histogramEnabled = false,
  contrastEnabled = false,
  imageWidth?: number,
  imageHeight?: number,
) => {
  if (
    sourceImage &&
    (brightnessLevel ||
      contrastLevel ||
      histogramEnabled ||
      contrastEnabled ||
      imageWidth ||
      imageHeight)
  ) {
    const image: any = await getBase64Image(sourceImage);
    const width = imageWidth || image.width;
    const height = imageHeight || image.height;

    const canvas = new OffscreenCanvas(width, height);
    const ctx = canvas.getContext('2d');
    ctx?.drawImage(image, 0, 0, width, height);
    const imageData = ctx?.getImageData(0, 0, width, height);
    const newData = empty(width, height);

    if (imageData?.data) {
      const finalData = applyFilters(
        imageData.data,
        newData,
        width,
        height,
        brightnessLevel,
        contrastLevel,
        histogramEnabled,
        contrastEnabled,
      );

      ctx?.clearRect(0, 0, width, height);
      ctx?.putImageData(new ImageData(finalData, width, height), 0, 0);
      const blob = await canvas.convertToBlob();
      const result = await blobToBase64(blob);
      return result;
    }
  }
  return sourceImage || '';
};

export const adjustBrightnessInRange = (value: number) => {
  if (value < MIN_BRIGHTNESS) {
    value = MIN_BRIGHTNESS;
  }
  if (value > MAX_BRIGHTNESS) {
    value = MAX_BRIGHTNESS;
  }
  return value;
};

export const adjustContrastInRange = (value: number) => {
  if (value < MIN_CONTRAST) {
    value = MIN_CONTRAST;
  }
  if (value > MAX_CONTRAST) {
    value = MAX_CONTRAST;
  }
  return value;
};

export const isTiffFile = (fileName: string | undefined | null) =>
  fileName && (fileName.toLowerCase().endsWith('.tif') || fileName.toLowerCase().endsWith('.tiff'));
