import { IconButton, useKeyPress } from '@clef/client-library';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Slider,
  Tooltip,
  TooltipProps,
  Typography,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import React, { useCallback, useMemo, useState } from 'react';
import { useDataBrowserState } from '../dataBrowserState';
import { useDataBrowserStyles } from '../styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { Defect, ModelStatus, UserPermission } from '@clef/shared/types';
import { useDefectSelector } from '../../../store/defectState/actions';
import { getDateNumber, getDefectColor } from '../../../utils';
import {
  generateModelNameByDate,
  NUM_SLIDER_STEPS,
  sliderValueLabelFormat,
  sliderValueToThresholdValue,
  thresholdValueToSliderValue,
} from './utils';
import ImageLevelClassificationSettings from './ImageLevelClassificationSettings';
import RunLive from '@/pages/DataBrowser/RunLive';
import { isUnsavedModel } from '@/utils/models';
import { useVPLatestModel } from '@/serverStore/projectModels';
import { useSaveModelMutation } from '@/serverStore/projectModels';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';
import { useHasPermission } from '@/hooks/useProjectRolePermissions';
import { ClientFeatures, useFeatureGateEnabled } from '@/hooks/useFeatureGate';
import ModelTrainingInProgressV2 from '@/pages/DataBrowser/ModelPerformance/ModelTrainingInProgressV2';
import DeployModelDialog from '@/components/Dialogs/DeployModelDialog';
import useStyles from './styles';
import { formatDistance } from 'date-fns';
import { NoiseFilterSection } from '@/components/Predict/NoiseFilterSection';
import { ModelPerformanceSidebarEmptyState } from '../ModelPerformance/ModelPerformanceSidebarEmptyState';
import { useModelStatusQuery } from '@/serverStore/projectModels';
import { isModelInEndState } from '@clef/shared/utils';

export const AccordionWrapper: React.FC<{ title: string; tooltipTitle: TooltipProps['title'] }> = ({
  title,
  children,
  tooltipTitle,
}) => {
  const styles = useStyles();

  return (
    <>
      <Accordion
        classes={{
          root: styles.accordion,
        }}
        defaultExpanded
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={styles.accordionSummary}>
          <Typography variant="h3">{title}</Typography>
          {tooltipTitle && (
            <Tooltip placement="top" arrow title={tooltipTitle} className={styles.accordionTooltip}>
              <InfoOutlined fontSize="small" />
            </Tooltip>
          )}
        </AccordionSummary>

        <AccordionDetails
          classes={{
            root: styles.accordionDetails,
          }}
        >
          {children}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export type NoiseThresholdSelectorProps = {
  defect: Defect;
  areaThreshold: number;
  setAreaThreshold: (newAreaThreshold: number) => void;
};

export const NoiseThresholdSelector: React.FC<NoiseThresholdSelectorProps> = ({
  defect,
  areaThreshold,
  setAreaThreshold,
}) => {
  const styles = useStyles();
  return (
    <Box>
      <Box className={styles.noiseThresholdClassInfo}>
        <Box
          className={styles.noiseThresholdClassColor}
          style={{ backgroundColor: getDefectColor(defect) }}
        ></Box>
        <Box className={styles.classNameText}>{defect.name}</Box>
      </Box>
      <Box className={styles.noiseThresholdSliderWrapper}>
        <Box>0</Box>
        <Slider
          className={styles.noiseThresholdSlider}
          track="inverted"
          valueLabelDisplay="auto"
          max={NUM_SLIDER_STEPS}
          value={thresholdValueToSliderValue(areaThreshold)}
          onChange={(event, value) =>
            setAreaThreshold(sliderValueToThresholdValue(value as number))
          }
          valueLabelFormat={sliderValueLabelFormat}
          scale={sliderValueToThresholdValue}
        ></Slider>
        <Box className={styles.infinityText}>&infin;</Box>
      </Box>
    </Box>
  );
};

export type InstantLearningModelsSidebarProps = {};

const InstantLearningModelsSidebar: React.FC<InstantLearningModelsSidebarProps> = () => {
  const styles = useStyles();
  const dataBrowserStyles = useDataBrowserStyles();
  const { dispatch } = useDataBrowserState();

  const closeSideBar = useCallback(() => {
    dispatch(draft => {
      draft.rightSidebar = undefined;
    });
  }, [dispatch]);
  useKeyPress('esc', closeSideBar);

  const instantLearningPostprocessingEnabled = useFeatureGateEnabled(
    ClientFeatures.InstantLearningPostprocessing,
  );
  const allDefects = useDefectSelector();

  const closeButton = useMemo(
    () => (
      <IconButton
        id="close-right-side-bar"
        className={dataBrowserStyles.rightSidebarCloseButton}
        onClick={closeSideBar}
      >
        <Close />
      </IconButton>
    ),
    [closeSideBar, dataBrowserStyles.rightSidebarCloseButton],
  );

  const { latestModel: currentModel, loading: isLoading, error } = useVPLatestModel();
  const { id: latestModelId, confidence: threshold } = currentModel ?? {};
  const { labelType, id } = useGetSelectedProjectQuery().data ?? {};

  const canDeployModel = useHasPermission(UserPermission.DeployModel);
  const [deployModelDialogOpen, setDeployModelDialogOpen] = useState(false);
  const { data: modelStatusRes } = useModelStatusQuery(id, latestModelId);
  const { status: modelStatus, metricsReady } = modelStatusRes ?? {};
  const saveModel = useSaveModelMutation();

  const modelActions = useMemo(() => {
    return (
      currentModel && (
        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          className={styles.modelActionButtonGroup}
        >
          {canDeployModel && (
            <RunLive
              isDeployPending={saveModel.isLoading}
              tooltipText={t('Prediction costs 1 credit for each image')}
              modelName={currentModel.modelName || t('Untitled model')}
              overwriteOnRunLiveButtonClick={() => {
                if (isUnsavedModel(currentModel)) {
                  saveModel.mutate(
                    {
                      id: latestModelId || '',
                      modelName: generateModelNameByDate(),
                    },
                    {
                      onSuccess: () => setDeployModelDialogOpen(true),
                    },
                  );
                } else {
                  setDeployModelDialogOpen(true);
                }
              }}
              modelId={latestModelId}
            />
          )}
        </Grid>
      )
    );
  }, [currentModel, styles.modelActionButtonGroup, canDeployModel, saveModel, latestModelId]);

  const currentModelDesc =
    currentModel && latestModelId ? (
      <Typography variant="caption">
        {isNaN(getDateNumber(currentModel.createdAt))
          ? t('Unknown date')
          : t('Current model was trained {{dateDistance}} ago', {
              dateDistance: formatDistance(getDateNumber(currentModel.createdAt), new Date()),
            })}
      </Typography>
    ) : (
      <ModelPerformanceSidebarEmptyState labelType={labelType} />
    );

  let content;
  if (!isLoading && !error && !currentModel?.id) {
    content = <ModelPerformanceSidebarEmptyState labelType={labelType} />;
  } else if (
    modelStatusRes?.modelId === latestModelId &&
    !isModelInEndState(modelStatus, metricsReady) &&
    // TODO: These two states will be removed. We can revisit this logic after we remove them.
    ![ModelStatus.FirstBatch, ModelStatus.ALLBatches].includes(modelStatus!)
  ) {
    content = (
      <>
        <Grid container alignItems="center" justifyContent="center">
          <ModelTrainingInProgressV2 modelId={latestModelId} />
        </Grid>
      </>
    );
  } else if (
    modelStatusRes?.modelId === latestModelId &&
    modelStatusRes?.status === ModelStatus.Failed
  ) {
    content = (
      <>
        <Typography variant="body1" gutterBottom>
          {t('Model training failed. Please try training a new model.')}
        </Typography>
      </>
    );
  } else if (
    modelStatusRes?.modelId === latestModelId &&
    modelStatusRes?.status === ModelStatus.Stopped
  ) {
    content = (
      <>
        <Typography variant="body1" gutterBottom>
          {t('Model training stopped.')}
        </Typography>
      </>
    );
  } else {
    content = (
      <>
        {currentModelDesc}
        {modelActions}
      </>
    );
  }

  return (
    <Box className={dataBrowserStyles.modelPerformanceSideBar}>
      <Box>
        <Typography variant="h3" className={styles.sectionSpacing}>
          {t('Models')}
        </Typography>
        <div className={styles.sectionSpacing}>{content}</div>
        {closeButton}
        <DeployModelDialog
          open={deployModelDialogOpen}
          onClose={() => setDeployModelDialogOpen(false)}
          modelInfo={{
            id: latestModelId,
            threshold: threshold ?? undefined,
          }}
        />
      </Box>
      {instantLearningPostprocessingEnabled && (
        <>
          <Divider light className={styles.divider} />
          <NoiseFilterSection />
          <Divider light className={styles.divider} />
          <AccordionWrapper
            title={t('Image-Level Classification')}
            tooltipTitle={t('Apply rules to assign an image-level output')}
          >
            {allDefects.length ? (
              <ImageLevelClassificationSettings />
            ) : (
              <Typography>{t('You can set rules after creating classes.')}</Typography>
            )}
          </AccordionWrapper>
        </>
      )}
    </Box>
  );
};

export default InstantLearningModelsSidebar;
