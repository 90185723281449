import { createReducer, createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  Defect,
  MetadataFormattedValue,
  ProjectWithUsers,
  RegisteredModelId,
} from '@clef/shared/types';
import { FileWithPath } from 'react-dropzone';
import { ReduxState, AppDispatch } from '..';
import {
  addFileConcurrency,
  AnomalyDefectionClassName,
  blobToBase64,
  isTiffFile,
  ParallelTaskQueue,
  pathParse,
} from '../../utils';
import { uploadMediaWithParallelization, fileListUniqueByKey, truncateList } from './utils';
import {
  UploadStatus,
  UploadStage,
  UploadFile,
  UploadMediaType,
  ClassifiedFolder,
  UploadState,
  UploadFailureReason,
  Split,
  DefectMap,
} from './types';
import {
  filesTruncatedWarningMsg,
  addSegmentationImageFile,
  addSegmentationMaskFile,
  addSegmentationDefectMapFile,
  deleteSegmentationImageFile,
  deleteSegmentationMaskFile,
  deleteSegmentationDefectMapFile,
  setFileWithNothingToLabel,
} from './segmentationActions';
import { pendoEntity } from '../../utils/pendo';
import { throttle } from 'lodash';
import { queryClient } from '@/serverStore';
import { datasetQueryKeys } from '@/serverStore/dataset';
import { tiffToPngListAsync } from '@clef/client-library/src/utils/tiffToPngList';

export * from './segmentationActions';

export const unassignedClassName = t('Unassigned (will be uploaded in raw status)');
export const duplicateFileWarningMsg = t(
  'Duplicate file names (without folder path) detected. Replaced old files with new files',
);

const initialState: UploadState = {
  uploadStage: UploadStage.NotStarted,
  uploadData: [],
  uploadMediaType: UploadMediaType.Null,
  classifiedFolders: [],
  metadata: {},
  split: null,
  tags: [],
  defectMap: null,
  segmentationMasks: [],
};

export const switchMediaUploadType = createAction<UploadMediaType>(
  'uploadState/switchMediaUploadType',
);
// file actions
export const deleteFile = createAction<string>('uploadState/deleteFile');
export const updateFile = createAction<
  Partial<UploadFile> & {
    key: string;
  }
>('uploadState/updateFile');
// folder action
export const deleteClassifiedFolder = createAction<string>('uploadState/deleteClassifiedFolder');
// clear all
export const resetUploadState = createAction<void>('uploadState/resetUploadState');
// thunk
export const startUpload = createAsyncThunk<
  ReduxState['uploadState'],
  { selectedProject?: ProjectWithUsers; selectedModelId?: RegisteredModelId },
  { state: ReduxState; dispatch: AppDispatch; rejectValue: ReduxState['uploadState'] }
>('uploadState/startUpload', async ({ selectedProject, selectedModelId }, thunkAPI) => {
  const { getState, dispatch, rejectWithValue } = thunkAPI;
  // Remove uploaded successfully files and duplicated files
  getState().uploadState.uploadData.forEach(file => {
    if (
      file.status === UploadStatus.Success ||
      file.failureReason === UploadFailureReason.Duplicated
    ) {
      dispatch(deleteFile(file.key));
    }
  });

  const { uploadData, metadata, split, tags } = getState().uploadState;
  const { selectedProjectId } = getState().project;

  pendoEntity?.track('upload_images', {
    projectType: selectedProject?.labelType ?? '',
    imageCount: uploadData.length,
    totalImageSize: uploadData.map(uData => uData.file.size).reduce((acc, size) => acc + size, 0),
  });

  const refreshMediasThrottled = throttle(
    () => {
      selectedProjectId &&
        queryClient.invalidateQueries(datasetQueryKeys.medias(selectedProjectId), undefined, {
          cancelRefetch: false,
        });
      selectedProjectId &&
        queryClient.invalidateQueries(datasetQueryKeys.mediaCount(selectedProjectId));
    },
    3000,
    { leading: false, trailing: true },
  );
  let allUploadSuccess = true;
  if (!selectedProject?.datasetId) return rejectWithValue(getState().uploadState);
  const uploadedMediaData = await uploadMediaWithParallelization(
    selectedProject.id,
    selectedProject.datasetId,
    // this function could also be used for retry
    uploadData.filter(uf => uf.status === UploadStatus.Pending),
    updatedFile => async (newStatus, newProgress, failureReason) => {
      if (newStatus === UploadStatus.Failure) {
        allUploadSuccess = false;
      }
      dispatch(
        updateFile({
          key: updatedFile.key,
          status: newStatus,
          progress: newProgress,
          failureReason,
        }),
      );
      if (newStatus === UploadStatus.Success) {
        refreshMediasThrottled();
      }
    },
    tags,
    metadata,
    split,
    selectedModelId,
  );

  if (allUploadSuccess) {
    return { ...getState().uploadState, uploadedMediaData } as unknown as UploadState;
  } else {
    return rejectWithValue(getState().uploadState);
  }
});

export const startClassifiedUpload = createAsyncThunk<
  ReduxState['uploadState'],
  { selectedProject?: ProjectWithUsers; selectedModelId?: RegisteredModelId },
  { state: ReduxState; dispatch: AppDispatch; rejectValue: ReduxState['uploadState'] }
>(
  'uploadState/startClassifiedUpload',
  // TODO Ideally defects should be in redux as well, for now it is in local hook, so need to be passed in
  async ({ selectedProject, selectedModelId }, thunkAPI) => {
    const { getState, dispatch, rejectWithValue } = thunkAPI;
    const { uploadData, classifiedFolders, metadata, split, tags } = getState().uploadState;
    let allUploadSuccess = true;

    await classifiedFolders.reduce(async (accPromise, classifiedFolder) => {
      // wait for previous defect to upload
      await accPromise;
      const uploadFileList = uploadData.filter(
        uf =>
          uf.classifiedFolder === classifiedFolder.folderName && uf.status === UploadStatus.Pending,
      );
      const uploadFileListWithLabel: UploadFile[] = uploadFileList.map(uploadFile => ({
        ...uploadFile,
        initialLabel:
          classifiedFolder.folderName === unassignedClassName
            ? undefined
            : {
                classification: classifiedFolder.folderName,
              },
      }));

      // in case some upload fails, we can directly retry
      uploadFileListWithLabel.forEach(fileWithLabel => dispatch(updateFile(fileWithLabel)));
      const { selectedProjectId } = getState().project;
      const refreshMediasThrottled = throttle(
        () => {
          selectedProjectId &&
            queryClient.invalidateQueries(datasetQueryKeys.medias(selectedProjectId), undefined, {
              cancelRefetch: false,
            });
          selectedProjectId &&
            queryClient.invalidateQueries(datasetQueryKeys.mediaCount(selectedProjectId));
        },
        3000,
        { leading: false, trailing: true },
      );

      if (!selectedProject?.datasetId) return Promise.reject();
      // upload all the media associated with this classifiedDefect
      await uploadMediaWithParallelization(
        selectedProject.id,
        selectedProject.datasetId,
        uploadFileListWithLabel,
        updatedFile => (newStatus, newProgress, failureReason) => {
          if (newStatus === UploadStatus.Failure) {
            allUploadSuccess = false;
          }
          dispatch(
            updateFile({
              key: updatedFile.key,
              status: newStatus,
              progress: newProgress,
              failureReason,
            }),
          );
          if (newStatus === UploadStatus.Success) {
            refreshMediasThrottled();
          }
        },
        tags,
        metadata,
        split,
        selectedModelId,
      );
      // move to next set
      return Promise.resolve();
    }, Promise.resolve());

    if (allUploadSuccess) {
      return getState().uploadState;
    } else {
      return rejectWithValue(getState().uploadState);
    }
  },
);

export const addFile = createAsyncThunk<
  UploadFile[],
  {
    files: FileWithPath[];
    capacity?: number | null;
    limit?: number | null;
    throwOnReachLimit?: boolean;
  },
  { state: ReduxState; dispatch: AppDispatch; rejectValue: void }
>('uploadState/addFile', async ({ files, capacity, limit, throwOnReachLimit }, thunkAPI) => {
  const { getState, rejectWithValue } = thunkAPI;
  const { uploadData } = getState().uploadState;

  const newAddedFileList: UploadFile[] = [];
  const addFileQueue = new ParallelTaskQueue(addFileConcurrency);
  files
    .filter(f => !f.name.startsWith('.'))
    .forEach(f => {
      addFileQueue.add(async () => {
        if (isTiffFile(f.name)) {
          const pngList = await tiffToPngListAsync(f);
          pngList.forEach((png, index) =>
            newAddedFileList.push({
              key: `${png.name}.${index}.png`,
              file: png,
              status: UploadStatus.NotStarted,
              progress: 0,
            }),
          );
          return;
        }
        newAddedFileList.push({
          key: f.path || f.name || '',
          file: f,
          status: UploadStatus.NotStarted,
          progress: 0,
        });
      }); // end of addFileQueue.add
    }); // end of forEach
  await addFileQueue.run();

  try {
    return truncateList(
      fileListUniqueByKey([...uploadData, ...newAddedFileList], duplicateFileWarningMsg),
      capacity,
      filesTruncatedWarningMsg(limit),
      throwOnReachLimit,
    );
  } catch (e) {
    return rejectWithValue(e);
  }
});

export const addObjectDetectionFile = createAsyncThunk<
  UploadFile[],
  {
    files: FileWithPath[];
    capacity?: number | null;
    limit?: number | null;
    throwOnReachLimit?: boolean;
  },
  { state: ReduxState; dispatch: AppDispatch; rejectValue: void }
>(
  'uploadState/addObjectDetectionFile',
  async ({ files, capacity, limit, throwOnReachLimit }, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const { uploadData } = getState().uploadState;

    const xmlFiles = files.filter(f => f.type === 'text/xml' && !f.name.startsWith('.'));
    const xmlFileNameToFileMap = new Map(xmlFiles.map(f => [pathParse(f.name).name, f]));
    const imageFiles = files.filter(
      f =>
        (!f.name.startsWith('.') && f.type.includes('image')) ||
        f.name.toLowerCase().endsWith('.mpo') ||
        isTiffFile(f.name),
    );

    const newAddedFileList: UploadFile[] = [];
    const addFileQueue = new ParallelTaskQueue(addFileConcurrency);
    imageFiles.forEach(f => {
      addFileQueue.add(async () => {
        const xml = xmlFileNameToFileMap.get(pathParse(f.name).name);
        const xmlDataURLString = xml ? await blobToBase64(xml) : undefined;

        const makeUploadFile = (
          file: FileWithPath | File,
          key: string,
          xmlDataURLString: string | undefined,
        ): UploadFile => {
          const uploadFile: UploadFile = {
            key,
            file,
            status: UploadStatus.NotStarted,
            progress: 0,
          };

          if (xmlDataURLString) {
            // readAsDataURL returns "data:text/xml;base64,xxxxxxxx"
            // need to remove the header part
            uploadFile.initialLabel = { objectDetection: xmlDataURLString.split(',').pop() };
          }

          return uploadFile;
        };

        if (isTiffFile(f.name)) {
          const pngList = await tiffToPngListAsync(f);
          // Only apply xml data for single-image TIFF.
          if (pngList.length === 1) {
            const uploadFile = makeUploadFile(pngList[0], f.name, xmlDataURLString);
            newAddedFileList.push(uploadFile);
          } else {
            pngList.forEach((png, index) => {
              const uploadFile = makeUploadFile(
                png,
                `${png.name}.${index}.png`,
                /* xmlDataURLString */ undefined,
              );
              newAddedFileList.push(uploadFile);
            });
          }
          return;
        }

        newAddedFileList.push(makeUploadFile(f, f.path || f.name || '', xmlDataURLString));
      }); // end of addFileQueue.add
    }); // end of forEach
    await addFileQueue.run();

    try {
      return truncateList(
        fileListUniqueByKey([...uploadData, ...newAddedFileList], duplicateFileWarningMsg),
        capacity,
        filesTruncatedWarningMsg(limit),
        throwOnReachLimit,
      );
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const addMetadata = createAction<MetadataFormattedValue>('uploadState/addMetadata');

export const addSplit = createAction<Split>('uploadState/addSplit');

export const addTags = createAction<string[]>('uploadState/addTags');

export const addDefectMap = createAction<DefectMap>('uploadState/addDefectMap');

export const addClassifiedFile = createAsyncThunk<
  { newFiles: UploadFile[]; newFolders: ClassifiedFolder[] },
  {
    files: FileWithPath[];
    existingDefects: Defect[];
    capacity?: number | null;
    limit?: number | null;
    throwOnReachLimit?: boolean;
  },
  { state: ReduxState; dispatch: AppDispatch; rejectValue: void }
>(
  'uploadState/addClassifiedFile',
  async ({ files, capacity, limit, throwOnReachLimit }, thunkAPI) => {
    const { getState, rejectWithValue } = thunkAPI;
    const state = getState().uploadState;

    const newAddedFileList: UploadFile[] = [];
    const addFileQueue = new ParallelTaskQueue(addFileConcurrency);
    files
      // filter out dotfiles (.DS_Store, etc)
      .filter(f => !f.name.startsWith('.'))
      // we only read one folder layer, any subfolder will be ignored
      .filter(f => f.path && f.path.split('/').length <= 3)
      .forEach(f => {
        addFileQueue.add(async () => {
          const fileNameSplit = f.path!.split('/').filter(split => !!split);
          // - fileNameSplit > 1 mean fileNameSplit[0] is folder fileNameSplit[1] is file name
          // - fileNameSplit = 1 mean no folder
          const classifiedFolder =
            fileNameSplit.length > 1 ? fileNameSplit[0].trim() : unassignedClassName;

          // For tiff file, need to extract all images
          if (isTiffFile(f.name)) {
            const pngList = await tiffToPngListAsync(f);
            pngList.forEach((png, index) =>
              newAddedFileList.push({
                key: `${png.name}.${index}.png`,
                file: png,
                status: UploadStatus.NotStarted,
                progress: 0,
                classifiedFolder,
              }),
            );
            return;
          }

          newAddedFileList.push({
            key: f.path || f.name || '',
            file: f,
            status: UploadStatus.NotStarted,
            progress: 0,
            classifiedFolder,
          });
        }); // end of addFileQueue.add
      }); // end of forEach
    await addFileQueue.run();

    try {
      const mergedFileList = truncateList(
        fileListUniqueByKey([...state.uploadData, ...newAddedFileList], duplicateFileWarningMsg),
        capacity,
        filesTruncatedWarningMsg(limit),
        throwOnReachLimit,
      );
      const classifiedFolders = mergedFileList
        .reduce((acc, uploadFile) => {
          const classifiedFolder = uploadFile.classifiedFolder!;
          if (acc.find(fd => fd.folderName === classifiedFolder)) {
            // folder already exist
            return acc;
          } else {
            // new folder
            return [
              ...acc,
              {
                folderName: classifiedFolder,
              },
            ];
          }
        }, [] as ClassifiedFolder[])
        // move unassignedClassName to the last class
        .sort((entryA, entryB) => {
          if (entryA.folderName === unassignedClassName) return 1;
          if (entryB.folderName === unassignedClassName) return -1;
          return 0;
        });

      return { newFiles: mergedFileList, newFolders: classifiedFolders };
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const addFileToClassifiedFolders = createAsyncThunk<
  { newFiles: UploadFile[]; newFolders: ClassifiedFolder[] },
  {
    files: FileWithPath[];
    existingDefects: Defect[];
    folder: AnomalyDefectionClassName;
  },
  { state: ReduxState; dispatch: AppDispatch; rejectValue: void }
>(
  'uploadState/addFileToClassifiedFolders',
  async (
    {
      files,
      folder,
    }: {
      files: FileWithPath[];
      folder: AnomalyDefectionClassName;
    },
    thunkAPI,
  ) => {
    const { getState } = thunkAPI;
    const state = getState().uploadState;
    let existingFolders = state.classifiedFolders;

    const newAddedFileList: UploadFile[] = [];
    const addFileQueue = new ParallelTaskQueue(addFileConcurrency);
    files
      .filter(f => !f.name.startsWith('.'))
      .forEach(f => {
        addFileQueue.add(async () => {
          if (isTiffFile(f.name)) {
            const pngList = await tiffToPngListAsync(f);
            pngList.forEach((png, index) =>
              newAddedFileList.push({
                key: `${png.name}.${index}.png`,
                file: png,
                status: UploadStatus.NotStarted,
                progress: 0,
                classifiedFolder: folder,
              }),
            );
            return;
          }
          newAddedFileList.push({
            key: f.path || f.name || '',
            file: f,
            status: UploadStatus.NotStarted,
            progress: 0,
            classifiedFolder: folder,
          });
        }); // end of addFileQueue.add
      }); // end of forEach
    await addFileQueue.run();

    if (!existingFolders.find(classifiedFolder => classifiedFolder.folderName === folder)) {
      existingFolders = [
        ...existingFolders,
        {
          folderName: folder,
        },
      ];
    }

    return {
      newFiles: fileListUniqueByKey(
        [...state.uploadData, ...newAddedFileList],
        duplicateFileWarningMsg,
      ),
      newFolders: existingFolders,
    };
  },
);

export default createReducer(initialState, builder => {
  builder.addCase(switchMediaUploadType, (state, action) => {
    state.uploadMediaType = action.payload;
  });
  builder.addCase(addMetadata, (state, action) => {
    state.metadata = action.payload;
  });

  builder.addCase(addSplit, (state, action) => {
    state.split = action.payload;
  });

  builder.addCase(addTags, (state, action) => {
    state.tags = action.payload;
  });

  builder.addCase(addDefectMap, (state, action) => {
    state.defectMap = action.payload;
  });
  /**
   * @ACTION: deleteFile
   */
  builder.addCase(deleteFile, (state, action) => {
    const fileKey = action.payload;
    const folderName = state.uploadData.find(
      uploadFile => uploadFile.key === fileKey,
    )!.classifiedFolder;
    const newUploadFileList = state.uploadData.filter(uploadFile => uploadFile.key !== fileKey);
    // update uploadData with new list
    state.uploadData = newUploadFileList;
    // if there are no more file in the classifiedFolder, remove the folder as well
    if (!newUploadFileList.find(uploadFile => uploadFile.classifiedFolder === folderName)) {
      state.classifiedFolders = state.classifiedFolders.filter(
        folder => folder.folderName !== folderName,
      );
    }
  });
  /**
   * @ACTION: updateFile
   */
  builder.addCase(updateFile, (state, action) => {
    const { key, status, progress, initialLabel, failureReason } = action.payload;
    const toUpdateFile = state.uploadData.find(f => f.key === key);
    if (toUpdateFile) {
      if (status) {
        toUpdateFile.status = status;
      }
      if (progress) {
        toUpdateFile.progress = progress;
      }
      if (initialLabel) {
        toUpdateFile.initialLabel = initialLabel;
      }
      if (failureReason) {
        toUpdateFile.failureReason = failureReason;
      }
    }
  });

  /**
   * @ACTION: deleteClassifiedFolder
   */
  builder.addCase(deleteClassifiedFolder, (state, action) => {
    const folderName = action.payload;
    state.classifiedFolders = state.classifiedFolders.filter(
      folder => folder.folderName !== folderName,
    );
    state.uploadData = state.uploadData.filter(
      uploadFile => uploadFile.classifiedFolder !== folderName,
    );
  });
  /**
   * @ACTION: resetUploadState
   */
  builder.addCase(resetUploadState, () => {
    return initialState;
  });
  /**
   * @ACTION: startClassifiedUpload.pending / startClassifiedUpload.fulfilled / startClassifiedUpload.rejected
   */
  builder.addCase(startClassifiedUpload.pending, state => {
    state.uploadStage = UploadStage.UploadInProgress;
    state.uploadData.forEach(uploadFile => {
      uploadFile.status = UploadStatus.Pending;
    });
  });
  builder.addCase(startClassifiedUpload.fulfilled, state => {
    state.uploadStage = UploadStage.UploadFulfilled;
    return state;
  });
  builder.addCase(startClassifiedUpload.rejected, state => {
    state.uploadStage = UploadStage.UploadFulfilledWithFailure;
    return state;
  });
  /**
   * @ACTION: startUpload.pending / startUpload.fulfilled / startUpload.rejected
   */
  builder.addCase(startUpload.pending, state => {
    state.uploadStage = UploadStage.UploadInProgress;
    state.uploadData.forEach(uploadFile => {
      // ignore already success ones
      if (uploadFile.status !== UploadStatus.Success) {
        uploadFile.status = UploadStatus.Pending;
      }
    });
  });
  builder.addCase(startUpload.fulfilled, state => {
    state.uploadStage = UploadStage.UploadFulfilled;
  });
  builder.addCase(startUpload.rejected, state => {
    state.uploadStage = UploadStage.UploadFulfilledWithFailure;
  });

  /**
   * @ACTION: addFile.fulfilled
   */
  builder.addCase(addFile.fulfilled, (state, action) => {
    state.uploadData = action.payload;
  });
  builder.addCase(addClassifiedFile.fulfilled, (state, action) => {
    state.uploadData = action.payload.newFiles;
    state.classifiedFolders = action.payload.newFolders;
  });
  builder.addCase(addFileToClassifiedFolders.fulfilled, (state, action) => {
    state.uploadData = action.payload.newFiles;
    state.classifiedFolders = action.payload.newFolders;
  });
  builder.addCase(addObjectDetectionFile.fulfilled, (state, action) => {
    state.uploadData = action.payload;
  });
  /**
   * @ACTIONS: Segmentation task
   */
  builder.addCase(addSegmentationImageFile.fulfilled, (state, action) => {
    state.uploadData = action.payload;
  });
  builder.addCase(deleteSegmentationImageFile.fulfilled, (state, action) => {
    state.uploadData = action.payload;
  });
  builder.addCase(addSegmentationMaskFile.fulfilled, (state, action) => {
    const { mask, data } = action.payload;
    state.segmentationMasks = mask;
    state.uploadData = data;
  });
  builder.addCase(deleteSegmentationMaskFile.fulfilled, (state, action) => {
    const { mask, data } = action.payload;
    state.segmentationMasks = mask;
    state.uploadData = data;
  });
  builder.addCase(addSegmentationDefectMapFile.fulfilled, (state, action) => {
    const { defect, data } = action.payload;
    state.defectMap = defect;
    state.uploadData = data;
  });
  builder.addCase(deleteSegmentationDefectMapFile.fulfilled, (state, action) => {
    const { defect, data } = action.payload;
    state.defectMap = defect;
    state.uploadData = data;
  });
  builder.addCase(setFileWithNothingToLabel.fulfilled, (state, action) => {
    state.uploadData = action.payload;
  });
});
