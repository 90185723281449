import InferenceAPI from '../api/inference_api';
import { ACCEPTED_IMAGE_EXTENSIONS, ACCEPTED_LABEL_EXTENSIONS } from '@clef/shared/constants/index';
import { replaceSpecialChars } from '@clef/shared/utils';
/**
 * upload id basically translates into S3 prefix, and the value
 * can be a DatasetId or an arbitrary string for cases like defect
 * book spectrum whose uploads are not associated with a particular
 * dataset and are stored in with prefix "undefined"
 */
type MediaLabelPair = {
  mediaFiles: File[] | null;
  labelFile: File | null;
  approved?: boolean;
};

export const pathParse = (pathString: string) => {
  const splitPathRe = /^((\/?)(?:[^/]*\/)*)((\.{1,2}|[^/]+?|)(\.[^./]*|))[/]*$/;

  const allParts = splitPathRe.exec(pathString)!.slice(1);
  if (!allParts || allParts.length !== 5) {
    throw new Error(`Invalid path '${pathString}'`);
  }

  return {
    root: allParts[1],
    dir: allParts[0].slice(0, -1),
    base: allParts[2],
    ext: allParts[4],
    name: allParts[3],
  };
};

export const getPredictionFromCloudInference = async (
  fileName: string,
  s3url: string,
  projectId: number,
  selectedModelId: string,
) => {
  const { data } = await InferenceAPI.getFromCloudInference(
    projectId,
    replaceSpecialChars(fileName, '_'),
    s3url,
    selectedModelId,
  );
  return data;
};

export const pairMediaLabelFiles = async (mediaAndLabelFiles: File[]) => {
  const mediaLabelPairs: Record<string, MediaLabelPair> = {};

  // pair each image with its corresponding label
  mediaAndLabelFiles.forEach(file => {
    const { name, ext } = pathParse(file.name);
    if (mediaLabelPairs[name] == null) {
      mediaLabelPairs[name] = { mediaFiles: [], labelFile: null } as MediaLabelPair;
    }
    // Fallback images without extension to .jpg (ext || '.jpg')
    // It should act as a gate to detect backend issues and be resilient
    if (ACCEPTED_IMAGE_EXTENSIONS.includes(ext?.toLowerCase() || '.jpg')) {
      mediaLabelPairs[name].mediaFiles?.push(file);
    } else if (ACCEPTED_LABEL_EXTENSIONS.includes(ext.toLowerCase())) {
      mediaLabelPairs[name].labelFile = file;
    }
  });
  return mediaLabelPairs;
};

const dataUrlToBlob = async (dataUrl: string) => {
  const res: Response = await fetch(dataUrl);
  const blob: Blob = await res.blob();
  return blob;
};

export const dataUrlToFile = async (
  dataUrl: string,
  fileName: string,
  mimeType: string,
): Promise<File> => {
  const blob = await dataUrlToBlob(dataUrl);
  return new File([blob], fileName, { type: mimeType });
};

export const acceptedImageFiles = ['image/jpeg', 'image/bmp', 'image/png', '.mpo', '.tiff', '.tif'];

export const addFileConcurrency = 5;
