import { BaseAPI } from './base_api';
import {
  ApiResponse,
  ModelStatus,
  ProjectId,
  ProjectModelInfo,
  RegisteredModel,
  DateStringISO,
} from '@clef/shared/types';

export type ModelStatusResponse = {
  modelId: string;
  status?: ModelStatus | null;
  metricsReady: boolean;
  createdAt: string;
  updatedAt?: string;
  datasetVersionId?: number;
};

class ProjectModelAPI extends BaseAPI {
  // This is only used to get default model metric key and custom training config
  async getProjectModelInfo(projectId: ProjectId): Promise<ApiResponse<ProjectModelInfo>> {
    return this.get('get_project_model_info', { projectId });
  }

  async postSetConfidence(modelId: string, confidence: number) {
    return this.postJSON('set_confidence', {
      modelId,
      confidence,
    });
  }
  async postRegisterModelId(projectId: ProjectId, modelId: string) {
    return this.postJSON('register', {
      projectId,
      modelId,
    });
  }

  async getModelStatus(
    projectId: ProjectId,
    modelId: string,
    lastUpdatedAt?: DateStringISO,
  ): Promise<ModelStatusResponse> {
    return this.get('model_status', { projectId, modelId, lastUpdatedAt }, true);
  }

  async getModels(projectId: ProjectId): Promise<RegisteredModel[]> {
    return this.get('fast_models', { projectId }, true);
  }

  async saveModel(modelId: string, modelName: string): Promise<ApiResponse<any>> {
    return this.postJSON('save_model_name', { modelId, modelName });
  }

  async updateModelBundleIsFav(
    modelId: string,
    threshold: number,
    isFav: boolean,
  ): Promise<ApiResponse<void>> {
    return this.postJSON('update_model_bundle_operations_favorite', { modelId, threshold, isFav });
  }

  async updateModelBundleIsDeleted(
    projectId: ProjectId,
    modelId: string,
    threshold: number,
    isDeleted: boolean,
  ): Promise<ApiResponse<void>> {
    return this.postJSON('update_model_bundle_operations_deleted', {
      projectId,
      modelId,
      threshold,
      isDeleted,
    });
  }

  async stopTraining(projectId: ProjectId, modelId: string) {
    return this.postJSON('stop_training', { projectId, modelId });
  }
}

export default new ProjectModelAPI('registered_model');
