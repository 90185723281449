/* eslint-disable no-redeclare */
import { useTypedSelector } from './useTypedSelector';
import { _internal_isFeatureEnabledInState } from '../utils/feature_utils';
import { ClientFeatures } from '@clef/shared/features';
import { LabelType } from '@clef/shared/types';
import { useGetSelectedProjectQuery } from '@/serverStore/projects';

// Since we have dedicated hooks for these ClientFeatures, we shall deprecate direct call
// of useFeatureGateEnabled with these ClientFeatures.

/**
 * @deprecated Please use `useAttentionHeatmapEnabled` instead
 */
export function useFeatureGateEnabled(featureName: ClientFeatures.ClassificationGradcam): boolean;
/**
 * @deprecated Please use `userAnomalyDetectionEnabled` instead
 */
export function useFeatureGateEnabled(featureName: ClientFeatures.MingruiAnomalyDetection): boolean;
/**
 * check if a feature is enabled
 */
export function useFeatureGateEnabled(featureName: ClientFeatures): boolean;
export function useFeatureGateEnabled(featureName: ClientFeatures) {
  return useTypedSelector(state => _internal_isFeatureEnabledInState(featureName, state));
}

// This implementation is the same with useFeatureGateEnabled but there's no deprecated signature for this hook
const useInternalFeatureGateEnabled = (featureName: ClientFeatures) =>
  useTypedSelector(state => _internal_isFeatureEnabledInState(featureName, state));

export const useFastNEasyEnabled = () => {
  const { data: selected } = useGetSelectedProjectQuery();

  // If label type is valid and is not anomaly detection, then return true as all other label types are supported with
  // fast-n-easy
  return !!selected?.labelType && selected.labelType !== LabelType.AnomalyDetection;
};

export const useAttentionHeatmapEnabled = () => {
  const { data: selected } = useGetSelectedProjectQuery();
  const isClassificationHeatmapEnabled =
    useInternalFeatureGateEnabled(ClientFeatures.ClassificationGradcam) &&
    selected?.labelType === LabelType.Classification;

  const isAnomalyDetectionHeatmapEnabled = selected?.labelType === LabelType.AnomalyDetection;

  return isClassificationHeatmapEnabled || isAnomalyDetectionHeatmapEnabled;
};

export const useFirstRunExperienceWorkflowAssistantEnabled = () => {
  const { labelType } = useGetSelectedProjectQuery().data ?? {};

  return (
    // As of 05/23, add workflow assistant to segmentation && classification
    labelType === LabelType.BoundingBox ||
    labelType === LabelType.Segmentation ||
    labelType === LabelType.Classification
  );
};

export const useAnomalyDetectionEnabled = () => {
  const isAnomalyDetectionEnabled = useInternalFeatureGateEnabled(
    ClientFeatures.MingruiAnomalyDetection,
  );
  return isAnomalyDetectionEnabled;
};

export const useModelAnalysisEnabled = () => {
  const { labelType } = useGetSelectedProjectQuery().data ?? {};
  return labelType !== LabelType.SegmentationInstantLearning;
};

export const useIsFeatureEnabledAndMayHideForSnowflake = () => {
  const isSnowflake = process.env.IS_SNOWFLAKE === 'true';
  const firstRunExperienceWorkflowAssistantEnabled =
    useFirstRunExperienceWorkflowAssistantEnabled();
  const isInstantLearningEnabled = useFeatureGateEnabled(
    ClientFeatures.SegmentationInstantLearning,
  );
  return {
    loadSampleDataButton: !isSnowflake,
    examplesPage: !isSnowflake,
    workflowAssistant: firstRunExperienceWorkflowAssistantEnabled,
    profileDropdown: true,
    visualPrompting: !isSnowflake && isInstantLearningEnabled,
    cloudDeployment: true,
    predictButton: true,
    apiCode: !isSnowflake,
    qrCode: !isSnowflake, // mobile upload qrcode
    trainingLogs: !isSnowflake,
    /** @deprecated We have a new UI for this. Don't need this flag now. We will remove it later. */
    stopTraining: false,
    webcam: !isSnowflake,
    downloadPascalVocCsvClassMap: true,
    mobileInference: !isSnowflake, // mobile inference qrcode
    creditReference: !isSnowflake,
  };
};

/**
 * @deprecated directly use `import { ClientFeatures } from '@clef/shared/features';` instead
 */
export { ClientFeatures };
