import React from 'react';
import { useTypedSelector } from '../../../../hooks/useTypedSelector';
import { Typography, Box } from '@material-ui/core';
import DropFileZone from '../components/DropFileZone';
import { useAppDispatch } from '../../../../store';
import { addObjectDetectionFile } from '../../../../store/uploadState';
import { useUploadStyles } from '../styles';
import { ReachLimitException } from '../../../../store/uploadState/utils';
import { useDialog } from '../../../Layout/components/useDialog';
import { ExceedType } from '../../../Layout/components/UsageExceedDialog';
import PicIcon from '../../../../images/pic.svg';
import LabelsIcon from '../../../../images/upload_labels.svg';
import { acceptedImageFiles } from '@/utils';

export interface ObjectDetectionUploadDropFileZoneProps {
  fileCapacity?: number | null;
  fileLimit?: number | null;
  disabled?: boolean;
  isNewCreditSystem?: boolean;
}

const ObjectDetectionUploadDropFile: React.FC<ObjectDetectionUploadDropFileZoneProps> = ({
  fileCapacity,
  fileLimit,
  disabled,
  isNewCreditSystem,
}) => {
  const styles = useUploadStyles();
  const uploadData = useTypedSelector(state => state.uploadState.uploadData);

  const dispatch = useAppDispatch();
  const { showUsageExceedDialog } = useDialog();

  return (
    <DropFileZone
      className={styles.whiteBg}
      onDrop={async files => {
        try {
          await dispatch(
            addObjectDetectionFile({
              files,
              capacity: fileCapacity,
              limit: fileLimit,
              throwOnReachLimit: isNewCreditSystem,
            }),
          ).unwrap();
        } catch (e) {
          if (e instanceof ReachLimitException) {
            showUsageExceedDialog(ExceedType.Upload, e.fileCount, fileCapacity!);
          }
        }
      }}
      accept={[...acceptedImageFiles, 'text/xml']}
      disabled={disabled}
    >
      <Typography variant="body1" component="div" className={styles.dropzoneText}>
        {t('Drag and drop ')}
        <strong>{t('folders/images ')}</strong>
        {t('here, or click to select files')}
      </Typography>
      {!uploadData.length && (
        <Box
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          display="flex"
          marginTop={10}
        >
          <Box marginBottom={5}>
            <img src={PicIcon} />
            <img className={styles.labelsIcon} src={LabelsIcon} />
          </Box>
          <Typography>
            {t('We accept PNG, JPEG, BMP and MPO image format, and Pascal Voc label format')}
          </Typography>
        </Box>
      )}
    </DropFileZone>
  );
};

export default ObjectDetectionUploadDropFile;
